import axios from "axios";
import store from "@/store";

/**
 * Axios instance
 */
const baseURL = process.env.VUE_APP_API_URL || "http://localhost:8000";

const axiosInstance = axios.create({
  baseURL,
  withCredentials: true
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const requestUrl = error.config?.url; // Get the request URL
    const isWhoAmIRoute = requestUrl && requestUrl.includes("whoami");

    if (error.response && error.response.status === 401 && !isWhoAmIRoute) {
      store.dispatch("auth/logout");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
